import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchConfig() {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/page-setups/payment-methods-list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/payment-methods', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/processing/payment-methods/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    applyToCustomer(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/processing/payment-method-shop-rates/${queryParams.id}/applyToCustomer?value=${queryParams.value}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/payment-methods/export', {
            params: queryParams,
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/vnd.ms-excel',
              Accept: 'application/vnd.ms-excel',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
}